@import "../../styles/base";

.resultWrapper {
    background: $white;
    position: absolute;
    z-index: 6;
    left: 0;
    margin-top: 15px;
    padding: 0 1.5rem 2rem;
    display: grid;

  @include fromTablet {
        padding: 2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border-radius: 0px 0px 24px 24px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @include fromDesktop {
        padding: 4rem;
    }

    a {
        color: $blue-dark;
        font-size: 1.6rem;
    }
}

.heading {
    flex: 0 0 100%;
    font-weight: normal;
    font-size: 2rem;

    @include untilTablet {
        display: none;
    }
}

.suggestions {
    @include fromTablet {
        flex: 0 0 30%;
        background: $blue-light;
        padding: 2rem;
        border-radius: 24px;
    }

    @include untilTablet {
      order: 2;
    }

    h3 {
        font-size: 2rem;
    }

    ul {
        margin-bottom: 2rem;
    }

    li {
        font-size: 1.4rem;
        margin-bottom: 5px;
    }
}

.products {
    display: flex;
    flex-direction: column;

    @include untilTablet {
        margin: 0 -10px 40px;
        gap: 20px;
        order: 1;
    }

    @include fromTablet {
        flex: 0 0 70%;
    }
}