@import "../../styles/base";

.searchWrapper {
    display: flex;
    border: solid 1px $blue;
    border-radius: 40px;
    overflow: hidden;
    background-color: $blue-light;
    position: relative;

    @include fromTablet {
        
    }

    @include fromDesktop {
    }
}

.searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    pointer-events: none;
}

.searchText {
    flex-grow: 1;
    width: 100%;
    -wekit-appearance: none;
    border: none;
    color: $blue-navey;
    padding: 0 1.5rem 0 4.8rem;
    line-height: 3.4;
    position: relative;
    font-size: 1.6rem;
    background-color: transparent;

    @include fromDesktop {
        font-size: 1.4rem;
    }

    &:focus {
        border: none !important;
        outline: 0;
    }

    &::placeholder {
        color: $blue-royal;
        font-style: italic;
      }
}

.searchBtn {
    color: $white;
    background: $blue-dark;
    padding: 1rem 2rem;
    margin: -1px -1px -1px 0;
    border-radius: 40px;
    transition: all 0.3s;
    border: none;
    cursor: pointer;

    @include fromTablet {
        padding: 1rem 4rem;
    }

    &:link,
    &:visited {
        color: $white;
    }

    &:hover,
    &:focus {
        background: darken($blue-dark, 10%);
    }
}