@import "../../styles/base";

.specialOffersContainer {
  padding: 0 3rem 3rem;

  @include untilTablet {
    padding: 2rem 0 0 0;
  }
}

.specialOffersLink {
  display: block;
}

.specialOffers {
  background: linear-gradient(90deg, #0039AF, #933CBC);
  border-radius: 16px;
  position: relative;
  color: #fff;
  border: none;
  background-size: cover;
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 12px 10px;

  &::before {
    content: url('../../images/special-offers/ebo/offer.svg');
    display: block;
    padding-right: 1rem;
    padding-left: 1rem;
    position: relative;
    top: 3px;

    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }

  &::after {
    content: '';
    height: 48px;
    width: 48px;
    display: block;
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: cover;
  }
}

.offerDescription {
  display: inline-block;
  text-underline-offset: 4px;
  margin-top: -1px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;

  &::before {
    content: url('../../images/special-offers/ebo/dining.svg');
    position: relative;
    top: 4px;
    padding-right: 5px;
  }

  &::after {
    content: url('../../images/special-offers/ebo/plus.svg');
    position: relative;
    top: 4px;
    padding-right: 2px;
  }

  @media only screen and (max-width: 1400px) {
    display: block;
    padding-left: 30px;

    &::before {
      position: absolute;
      padding-right: 0;
      left: 5px;
    }
  }
}

.offerCode {
  font-size: 14px;
  position: relative;
  top: -1px;

  span {
    font-weight: bold;
    text-decoration: underline;
  }

  &::before {
    content: url('../../images/special-offers/ebo/tickets.svg');
    position: relative;
    top: 5px;
    padding-right: 5px;
  }

  @media only screen and (max-width: 1400px) {
    display: block;
    padding-left: 30px;
    top: 0;

    &::before {
      position: absolute;
      padding-right: 0;
      left: 5px;
      top: 0;
    }
  }
}
