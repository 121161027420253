@import "../../styles/base";

.productList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include fromTablet {
        padding: 0 2rem 2rem;
        display: flex;
        flex-wrap: wrap;
    }

    @include fromDesktop {
        padding: 0 3rem 1rem;
    }
}

.productItem {
    width: 50%;
    padding: 1rem;

    @include fromTablet {
        width: 25%;
        padding: 0 1.5rem 1.5rem;
    }

    span {
        display: block;
        margin-top: 5px;
        line-height: 1.4;
    }
}

.imgWrapper {
    position: relative;
    padding-bottom: 75%;
    height: 0;
    overflow: hidden;
    border-radius: 14px;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.btn {
    margin: auto;
    color: $white;
    background: $blue-navey;
    padding: 1rem 2rem;
    border-radius: 14px;
    transition: all 0.3s;

    &:link,
    &:visited {
        color: $white;
    }

    &:hover,
    &:focus {
        background: darken($blue-navey, 10%);
    }
}